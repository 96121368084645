import React from "react"
import data from "../../content/memo-quest-data.json"
import GameFeatures from "../components/GameFeatures"
import GamePresentationBox from "../components/GamePresentationBox"
import GameScreenshots from "../components/GameScreenshots"
import GameVideo from "../components/GameVideo"
import StoreBar from "../components/StoreBar"
import SEO from "./SEO"
import ContactInfo from "./ContactInfo"

interface Props {}

const MemoQuestContent: React.FC<Props> = () => {
  return (
    <GamePresentationBox data={data}>
      <SEO description={data.presentation.text} title={data.gameName} />
      <StoreBar gameId={data.gameId} stores={data.stores} />
      <GameScreenshots data={data} />
      <GameFeatures data={data} />
      <ContactInfo />
    </GamePresentationBox>
  )
}

export default MemoQuestContent
