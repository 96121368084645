import React from "react"
import MemoQuestContent from "../../components/MemoQuestContent"
import Website from "../../templates/Website"

interface Props {}

const MemoQuest: React.FC<Props> = () => {
  return (
    <Website>
      <MemoQuestContent />
    </Website>
  )
}

export default MemoQuest
